import {
  IconBox,
  IconBucketDroplet,
  IconChartArea,
  IconTemperatureSun,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  cippusHeight,
  cippusVolume,
  strappingMax,
  tekADCHeight,
  tekADCVolume,
  tekMTBHeight,
  tekMTBVolume,
} from "@joy/shared-calculator";
import {
  displayPercent,
  displaySignal,
  displayTemperature,
  displayVolume,
  roundFloat,
  specialChars,
} from "@joy/shared-utils";

import {
  Box,
  Chart,
  IndicatorRow,
  displayBattery,
  displaySignalStrength,
  pageParts,
} from "../../components";
import { gaugeQuery } from "../../data";
import { useChart } from "../../hooks";

export const GaugeCalculatorPanel = ({ gaugeId }: { gaugeId: string }) => {
  const {
    data: { detail, observation, strappingTable, config },
    isLoading,
  } = useSuspenseQuery(gaugeQuery(gaugeId));

  const latest = useMemo(() => {
    if (!observation) return undefined;

    const max = strappingMax(strappingTable, "l");
    let height;
    let volume;
    switch (config.__typename) {
      case "CippusConfig":
        height = cippusHeight(observation.reading, { config, strappingTable });
        volume = cippusVolume(observation.reading, { config, strappingTable });
        break;
      case "TekADCConfig":
        height = tekADCHeight(observation.reading, { config, strappingTable });
        volume = tekADCVolume(observation.reading, { config, strappingTable });
        break;
      case "TekRadarConfig":
      case "TekUltrasonicConfig":
        height = tekMTBHeight(observation.reading, {
          config,
          detail,
          strappingTable,
        });
        volume = tekMTBVolume(observation.reading, {
          config,
          detail,
          strappingTable,
        });
        break;
    }

    return {
      ...observation,
      volume,
      height,
      max,
    };
  }, [observation, detail, config, strappingTable]);

  const { indicators } = useChart({
    data: [],
    latest: latest,
    deps: [detail.unit],
    elements: (c) => [
      c.accessor((d) => d.reading, {
        label: "Reading",
        variant: "emerald",
        format: (value) =>
          `${roundFloat(value, { precision: 3 })} ${detail.unit}`,
        fallback: `${specialChars.endash} ${detail.unit}`,
        icon: <IconBucketDroplet />,
      }),
      c.accessor((d) => d.volume, {
        label: "Volume",
        variant: "emerald",
        format: (value) => displayVolume(value, "l"),
        icon: <IconBox />,
      }),
      c.accessor((d) => d.signal, {
        label: "Signal",
        variant: "purple",
        format: (value) => displaySignal(value),
        icon: (value) => displaySignalStrength(value),
      }),
      c.accessor((d) => d.temperature, {
        label: "Temperature",
        variant: "red",
        format: (value) => displayTemperature(value),
        icon: <IconTemperatureSun />,
      }),
      c.accessor((d) => d.voltage, {
        label: "Battery",
        variant: "sky",
        format: (value) => displayPercent(value),
        icon: (value) => displayBattery(value),
      }),
      c.accessor((d) => d.rssi, {
        label: "RSSI",
        teamPermission: "admin",
        variant: "indigo",
        format: (value) => value,
        icon: undefined,
      }),
      c.accessor((d) => d.src, {
        label: "SRC",
        teamPermission: "admin",
        variant: "slate",
        format: (value) => value,
        icon: undefined,
      }),
    ],
  });

  return (
    <div className={pageParts.page}>
      <Box
        header={{
          title: "Current Status",
          edit: {
            to: `/gauges/$gaugeId`,
            params: { gaugeId },
            search: { action: "config" },
            text: "Edit Configuration",
          },
        }}
      >
        <IndicatorRow indicators={indicators} />
      </Box>
      <Box
        header={{
          title: "Strapping Table",
          edit: {
            to: `/gauges/$gaugeId`,
            params: { gaugeId },
            search: { action: "strapping" },
            text: "Edit Rows",
          },
        }}
      >
        <Chart
          xScale={{ type: "linear", zero: true }}
          xFormat={(d) => `${d} l`}
          yScale={{ type: "linear", zero: true }}
          yFormat={(d) => `${d} mm`}
          mock={() => [
            { mm: 0, l: 0 },
            { mm: 50, l: 400 },
            { mm: 100, l: 1000 },
          ]}
          loading={isLoading}
          data={strappingTable}
          empty={{
            icon: IconChartArea,
            text: "Strapping tables require at least two points to display.",
          }}
          series={[
            {
              key: "strap",
              label: "Strapping Table",
              default: true,
              variant: "sky",
              type: "area",
              xAccessor: (d) => d.l,
              yAccessor: (d) => d.mm,
            },
          ]}
          additionalData={[
            {
              key: "status",
              label: "Current Status",
              default: true,
              variant: "emerald",
              type: "line",
              display: {
                lineProps: {
                  strokeDasharray: "6 4",
                },
                enableEvents: false,
              },
              xAccessor: (d) => d.l,
              yAccessor: (d) => d.mm,
              custom: latest?.max
                ? [
                    { mm: latest.height, l: 0 },
                    { mm: latest.height, l: latest.volume },
                    {
                      mm: latest.height,
                      l: Math.max(latest.max || 0, latest.volume || 0),
                    },
                  ]
                : [],
            },
          ]}
          tooltip={({ item }) => (
            <p>
              <span className="font-semibold">{item.mm}</span> mm ={" "}
              <span className="font-semibold">{item.l}</span> l
            </p>
          )}
        />
      </Box>
    </div>
  );
};
