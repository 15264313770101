import { IconCalculator, IconHistory, IconSettings } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { technologyIcons } from "../../../assets";
import {
  ItemHeader,
  NotFound,
  Tabs,
  Time,
  pageParts,
} from "../../../components";
import {
  GaugeProduct,
  gaugeQuery,
  singleItemLoader,
  useAuth,
} from "../../../data";
import {
  ChangeHistoryPanel,
  GaugeActionPanel,
  GaugeCalculatorPanel,
  GaugeInfoPanel,
  GaugeSettingsPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z
    .enum([
      "edit",
      "config",
      "strapping",
      "disable",
      "schedule",
      "sonic",
      "additional",
      "delete",
      "download",
    ])
    .optional(),
  comment: z.string().optional(),
});

const showSettings: Record<GaugeProduct, boolean> = {
  Cippus: false,
  TekADC: true,
  TekRadar: true,
  TekUltrasonic: true,
  Unregistered: false,
};

export const Route = createFileRoute("/_user/gauges/$gaugeId")({
  validateSearch,
  component: Component,
  notFoundComponent,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, gaugeQuery(params.gaugeId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.name,
      },
    ],
  }),
});

export function notFoundComponent() {
  const { hasTeamPermission } = useAuth();

  return hasTeamPermission("admin") ? (
    <NotFound
      item="Gauge"
      action={{ to: "/gauges", search: { action: "upload" } }}
      actionLabel="Add gauge"
    />
  ) : (
    <NotFound
      item="Gauge"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  );
}

export function Component() {
  const gaugeId = Route.useParams({ select: (p) => p.gaugeId });
  const { data } = useSuspenseQuery(gaugeQuery(gaugeId));
  const { hasTeamPermission } = useAuth();

  return (
    <div className={pageParts.page}>
      <ItemHeader
        image={technologyIcons[data.technology]}
        title={data.name}
        subtitle={
          <Time date={data.observation?.updatedAt} prefix="Last active " />
        }
      />
      <Tabs
        name="gauge"
        tabs={[
          {
            name: "Config",
            icon: IconCalculator,
            panel: <GaugeCalculatorPanel gaugeId={gaugeId} />,
          },
          {
            name: "Settings",
            icon: IconSettings,
            hidden: !showSettings[data.product],
            panel: <GaugeSettingsPanel gaugeId={gaugeId} />,
          },
          {
            icon: IconHistory,
            name: "History",
            hidden: !hasTeamPermission("admin"),
            panel: <ChangeHistoryPanel entityType="gauge" entityId={gaugeId} />,
          },
        ]}
        info={<GaugeInfoPanel gaugeId={gaugeId} />}
      />
      <GaugeActionPanel />
    </div>
  );
}
