import {
  Field,
  Label,
  TextField,
  TextInputField,
  fieldParts,
} from "../../components";
import { GaugeConfigContentProps } from "../../data";

export const CippusContent = ({
  form,
  validators,
}: GaugeConfigContentProps) => (
  <>
    <Field>
      <Label htmlFor="specificGravityProbe">Specific Gravity</Label>
      <div className={fieldParts.row}>
        <form.Field
          name="specificGravityProbe"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Probe"
              inputMode="decimal"
              addon="PROBE"
              autoFocus
            />
          )}
        />
        <div> : </div>
        <form.Field
          name="specificGravityActual"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Actual"
              inputMode="decimal"
              addon="PRODUCT"
            />
          )}
        />
      </div>
    </Field>
    <form.Field
      name="offsetHeight"
      validators={validators.offset}
      children={(field) => (
        <TextField
          field={field}
          label="Height Offset (mm)"
          inputMode="decimal"
        />
      )}
    />
    <form.Field
      name="offsetVolume"
      validators={validators.offset}
      children={(field) => (
        <TextField
          field={field}
          label="Volume Offset (l)"
          inputMode="decimal"
        />
      )}
    />
  </>
);
